type ApiPath = {
  partnerAuth: string;
  settings: (apikey: string) => string;
  statEvent: string;
};

const apiPath: ApiPath = {
  partnerAuth: `${process.env.REACT_APP_AUTH_SERVICE}/settings`,
  statEvent: `${process.env.REACT_APP_STATISTICS}/stat/event`,
  settings: (apiKey) => `/settings/?apiKey=${apiKey}`,
};

export { apiPath };
