import React, { useEffect, useState } from "react";
import { track } from "@amplitude/analytics-browser";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";

import AnalyticsService from "./services/AnalyticsService";
import { AnalyticsProps } from "./models/analytics";
import AuthService from "./services/AuthService";

const App = function App() {
  const [apikey, setApiKey] = useState("");
  const [amplitudeApiKey, setAmplitudeApiKey] = useState("");
  const [clientId, setClientId] = useState("");
  const [partner, serPartner] = useState("");

  let objProps: AnalyticsProps;

  const getScreenSize = () => `${window.innerWidth}x${window.innerHeight}`;

  const getProps = (clientId: string) => {
    objProps = {
      apikey,
      partner,
      user_id: clientId,
      screen_size: getScreenSize(),
      platformUserAgent: navigator.userAgent,
    };
    return objProps;
  };

  const sendAnalytics = (clientId: string) => {
    track("partner-load", getProps(clientId));
    AnalyticsService.sendTechnicalAnalytics({
      event: "partner-load",
      value: { ...getProps(clientId) },
    });
  };

  const auth = async () => {
    const response = await AuthService.auth(apikey);
    if (response?.status === "success") {
      axios.defaults.baseURL = response.data.backendUrl;
      serPartner(response.data.project);
      const resp = await AuthService.getAmplitudeKey(apikey);
      if (resp?.status === "success") {
        setAmplitudeApiKey(resp.data.amplitudeApiKey);
      }
    }
  };

  useEffect(() => {
    if (apikey) {
      auth();
    }
  }, [apikey]);

  useEffect(() => {
    if (amplitudeApiKey) {
      amplitude.init(amplitudeApiKey, {
        defaultTracking: false,
      });
      amplitude.setUserId(clientId);
      sendAnalytics(clientId);
    }
  }, [amplitudeApiKey]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const apikeyFromUrl = params.get("apikey");
    if (apikeyFromUrl) setApiKey(apikeyFromUrl);

    const clientIdFromLS = window.localStorage?.getItem("clientId");
    if (clientIdFromLS) {
      setClientId(clientIdFromLS);
      return;
    }
    const id = crypto.randomUUID();
    setClientId(id);
    window.localStorage.setItem("clientId", id);
  }, []);

  return <div className="App"></div>;
};

export default App;
