import axios from "axios";
import { apiPath } from "../constants/api";
import { TechnicalAnalyticsProps } from "../models/analytics";

export default class AnalyticsServices {
  static sendTechnicalAnalytics = async (
    analyticsData: TechnicalAnalyticsProps
  ) => {
    const { event, value } = analyticsData;
    const params = {
      event,
      value,
      key: "JGMHFqonZuwKeoly+bzNnw==",
    };
    await axios
      .post<TechnicalAnalyticsProps>(apiPath.statEvent, params)
      .catch((err) => console.error(err));
  };
}
