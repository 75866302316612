import axios from "axios";
import { apiPath } from "../constants/api";

export default class AuthServices {
  static auth = async (apikey: string) => {
    try {
      const { data, status } = await axios.post(apiPath.partnerAuth, {
        apiKey: apikey,
      });
      return { status: "success", data };
    } catch (error) {
      console.log(error);
    }
  };

  static getAmplitudeKey = async (apikey: string) => {
    try {
      const { data, status } = await axios.get(apiPath.settings(apikey));
      return { status: "success", data };
    } catch (error) {
      console.log(error);
    }
  };
}
